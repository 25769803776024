import React from 'react';
import PropTypes from 'prop-types';
import Domain from './Domain';
import ExactDomain from './ExactDomain';

const SearchResults = ({ results, cartClick, plid, text, baseUrl }) => {
  const {
    exactMatchDomain,
    suggestedDomains,
    disclaimer
  } = results;
  const divStyle = {
    'width': '100%',
    'max-width': '800px',
    'margin': '0 auto',
  };
  return (
    <div style={divStyle} className="row">
   <div>
		    <ul className="search-results-padding">
		    </ul>
		<div className="unavailable" style={{'padding': '1.67rem'}}>
    <ExactDomain domainResult={ exactMatchDomain } text={ text } />
    </div>
    <ul id="search-results" className="pricelist">
      
      
        <Domain domainResult={ exactMatchDomain } text={ text } cartClick={ cartClick }/>
    </ul>
      <ul id="search-results" className="pricelist">
        { suggestedDomains && suggestedDomains.map((domainResult, index) => {
          return domainResult.available && (<Domain key={ index } domainResult={ domainResult } text={ text } cartClick={ cartClick }/>);
        })}
      </ul>
      <div className="rstore-disclaimer"><pre>{ disclaimer }</pre></div>
    
   </div>
  </div>
  );
};

SearchResults.propTypes = {
  results: PropTypes.object.isRequired,
  cartClick: PropTypes.func.isRequired,
  text: PropTypes.object.isRequired
}

export default SearchResults;
