import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import SearchResults from './SearchResults';

import util from './util';

const initialState = {
  results: null,
  searching: false,
  submitting: false,
  error: '',
  selectedDomains: [],
  hasError: false,
  domain: ''
};

export default class DomainSearch extends Component {
  constructor() {
    super(...arguments);

    this.handleChange = this.handleChange.bind(this);
    this.handleDomainSearch = this.handleDomainSearch.bind(this);
    this.handleContinueClick = this.handleContinueClick.bind(this);

    this.state = initialState;
  };

  componentDidMount() {
    if (this.props.domainToCheck) {
      this.setState({ domain: this.props.domainToCheck });
      this.search(this.props.domainToCheck);
    }
  }

  handleChange(event) {
    this.setState({ domain: event.target.value });
  }

  search(q) {
    const {
      baseUrl,
      plid,
      pageSize
    } = this.props;

    this.setState({ searching: true });

    const domainUrl = `https://www.${baseUrl}/api/v1/domains/${plid}/`;

    return util.fetchJsonp(domainUrl, { pageSize, q }).then(data => {
        this.setState({
          results: data.exactMatchDomain ? data : null,
          error: data.error ? data.error.message : '',
          searching: false
        });
      }).catch(error => {
        this.setState({
          searching: false,
          error: error.message
        });
      });
  }

  handleDomainSearch(e) {
    e.preventDefault();
    if (this.state.domain.length > 0) {
      this.search(this.state.domain);
    }
  }

  handleSelectClick(domainObj) {
    const { selectedDomains } = this.state;

    const index = selectedDomains.indexOf(domainObj.props.domainResult);
    let newSelectDomains = [];

    if (index >= 0 ){
      newSelectDomains = [
        ...selectedDomains.slice(0, index),
        ...selectedDomains.slice(index + 1)
      ];

      domainObj.setState({
        selected: false,
      });
    }
    else {
      newSelectDomains = [
        ...selectedDomains,
        domainObj.props.domainResult
      ];

      domainObj.setState({
        selected: true,
      });
    }

    this.setState({ selectedDomains: newSelectDomains });
  }

  handleContinueClick() {
    this.setState({ submitting: true })
  }

  generateCartItems() {
    const {
      selectedDomains,
      results
    } = this.state;

    const items = [];

    if (results) {
      let domains;

      if (selectedDomains.length === 0 && results.exactMatchDomain.available) {
        domains = [results.exactMatchDomain];
      }
      else {
        domains = selectedDomains;
      }

      domains.forEach(item => {
        items.push({
          id: 'domain',
          domain: item.domain
        });
      });
    }

    return JSON.stringify(items);
  }

  render() {
    const {
      baseUrl,
      plid,
      text,
      newTab
    } = this.props;

    const {
      searching,
      submitting,
      results,
      selectedDomains,
      error
    } = this.state;

    const domainCount = selectedDomains.length;
    const hasExactMatch = results && results.exactMatchDomain && results.exactMatchDomain.available;
    const cartUrl = `https://www.${baseUrl}/api/v1/cart/${plid}/?redirect=true`;
    const items = this.generateCartItems();

    // Prevent navigation when domains are selected and user attempts to navigate
    // outside of the domain purchase path
    window.onbeforeunload = () => {
      // Most browsers control the return message to the user, we can safely return an empty string here.
      return !submitting && (hasExactMatch || domainCount > 0) ? '' : undefined;
    };

    return (
      <Fragment>
        <form id="search-form" className="search" name="search-form" onSubmit={ this.handleDomainSearch }>
          <label for="search-term" class="inp">
            <input
              type="search"
              id="search-term"
              name="domain"
              value={ this.state.domain }
              onChange={ this.handleChange }
              className="search-field"
              placeholder={ text.placeholder }
            />
            <span class="border">
            </span>
        </label>    
            <button
              type="submit"
              v-on="click: click"
              aria-label="domain search"
              >
                <i class="fal fa-search"></i>
              </button>
          </form>


        { results &&
          <form className="continue-form" method="POST" action= { cartUrl } target={newTab ? '_blank' : '_self'}>
            <input type="hidden" name="items" value={ items } />
            <div id="java-cart" className="btn btn-standard">
            <div className="inner">
            <h4>
            <i class="fal fa-shopping-cart"></i>
            <span><button
              
              className="buttoncart" onClick={ this.handleContinueClick }
              disabled={ domainCount === 0 && !hasExactMatch }
            >
              
              { (domainCount > 0) && `(${domainCount})` }
            </button>
            </span>
            </h4>
            </div>
            </div>
          </form>
        }

        { error && <div className="rstore-error">Error: { error }</div> }
        { (searching || submitting) && !newTab && <div className="rstore-loading"></div> }
        { results && <SearchResults results={ results } cartClick={ domain => this.handleSelectClick(domain) } text={ text }/> }
      </Fragment>
    );
  }
}

DomainSearch.propTypes = {
  plid: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired
}
